import React, {useState, useEffect} from "react";
import TVEManagerDataProvider from "../../../Services/TVEManagerDataProvider";
import {Grid, Tab} from "semantic-ui-react";
import GroupConfigEditorMasterDetailView from "./GroupConfigEditorMasterDetailView";
import PlatformConfigEditorMasterDetailView from "./PlatformConfigEditorMasterDetailView";

export default function MVPDConfigEditorMasterDetailView(props) {
    const [brands, setBrands] = useState([]);

    TVEManagerDataProvider.init(process.env.TVE_MANAGER_URL);

    useEffect(function initializeState() {
        TVEManagerDataProvider.getBrands().then(response => {
            setBrands(Array.isArray(response.brands) ? response.brands : []);
        });
    }, []);

    return (
        <Grid>
            <Grid.Column width={16}>
                <Tab
                    panes={[
                        {
                            menuItem: {
                                key: "platform-config-editor-tab",
                                content: <label>Platform Configuration Editor</label>
                            },
                            render: () =>
                                <PlatformConfigEditorMasterDetailView
                                    brands={brands}
                                    toast={props.toast}
                                    TVEManagerDataProvider={TVEManagerDataProvider}
                                />
                        },
                        {
                            menuItem: {
                                key: "group-config-editor-tab",
                                content: <label>Group Configuration Editor</label>
                            },
                            render: () =>
                                <GroupConfigEditorMasterDetailView
                                    brands={brands}
                                    toast={props.toast}
                                    TVEManagerDataProvider={TVEManagerDataProvider}
                                />
                        }
                    ]}
                />
            </Grid.Column>
        </Grid>
    );
};
