import React, {Component} from 'react';
import MediumMetadataProvider from "../../../Services/MediumAdminProvider";
import MediumAdminRegisterForm from "./MediumAdminRegisterForm";
import MediumAdminCacheInvalidation from "./MediumAdminCacheInvalidationForm"
import MediumAdminMetadataForm from "./MediumAdminMetadataForm"
import MediumAdminSSAIToggleButton from "./MediumAdminSSAIToggleButton";
import { Grid } from "semantic-ui-react";


export default class MediumAdminMasterDetailView extends Component {
    toastSetFailed =  { type: 'error', title: 'Update Failed', time: 5000};
    toastGetFailed =  { type: 'error', title: 'Update Failed', time: 5000, description: "Unable to fetch metadata"};
    toastSetSuccess = { type: 'success', title: 'Metadata Updated', time: 3500 };

    constructor(props) {
        super(props);
        this.state = {
            metadata: {},
            ssaiEnabled: true,
            environment: process.env.ENVIRONMENT_NAME
        };
        MediumMetadataProvider.init({ baseURL: process.env.MEDIUM_URL, synapseCoreUrl: process.env.SYNAPSE_CORE_URL });
    }

    componentDidMount() {
        this.getMetadata();
    }

    toggleMetadataLoading = () => {
        this.setState(state => {return {metadataLoading: !state.metadataLoading}});
    };

    getMetadata = () => {
        this.setState({
            metadataLoading: true,
            metadataLoadingText: "Fetching Metadata"
        }, () => {
            MediumMetadataProvider.get().then(resp => {
                resp.json().then(metadata => {
                    let data = metadata;
                    console.log(`Metadata: ${data}`);
                    console.log(`ssaiEnabled: ${data.ssaiEnabled}`);
                    console.log(`ssaiEnabled.enabled: ${data.ssaiEnabled.enabled}`);
                    if (metadata.error) {
                        this.props.toast(this.toastGetFailed)
                        data = {}
                    }
                    this.setState({
                        metadata: data,
                        ssaiEnabled: data.ssaiEnabled.enabled
                    }, () => { this.toggleMetadataLoading() })
                });
            });
        });
    };

    setMetadata = (key, oldValue, newValue) => {
        try {
            this.setState({
                metadataLoading: true,
                metadataLoadingText: `Updating ${key} node`
            }, () => {
                MediumMetadataProvider.set(key, oldValue, newValue).then(resp => {
                    resp.json().then(metadata => {
                        this.toggleMetadataLoading()
                        this.toastSetSuccess.description = `Updated "${key}" node`
                        this.props.toast(this.toastSetSuccess)
                    });
                });
            });
        } catch (error) {
            this.toastSetFailed.description = `Unable to update "${key}" node`;
            this.props.toast(toastSettings);
        }
    };

    render() {
        return (
            <Grid className="masterContainer" divided="vertically">
                <Grid.Row>
                    <Grid.Column width={16}>
                        <MediumAdminMetadataForm
                            metadata={this.state.metadata}
                            environment={this.state.environment}
                            metadataLoading={this.state.metadataLoading}
                            metadataLoadingText={this.state.metadataLoadingText}
                            setMetadata={this.setMetadata}
                            toast={this.props.toast} />
                    </Grid.Column >
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={3}>
                        <MediumAdminSSAIToggleButton key={this.state.ssaiEnabled} ssaiEnabled={this.state.ssaiEnabled} getMetadata={this.getMetadata} />
                    </Grid.Column>
                    <Grid.Column width={2} />
                    <Grid.Column width={4}>
                        <MediumAdminRegisterForm />
                    </Grid.Column>
                    <Grid.Column width={1} />
                    <Grid.Column width={4}>
                        <MediumAdminCacheInvalidation environment={this.state.environment}/>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}
