import React from "react";
import { Header, Form, Message, Confirm } from "semantic-ui-react";
import MediumAdminDataProvider from "../../../Services/MediumAdminProvider";


class MediumAdminCacheInvalidation extends React.Component {
    headerFontStyle = {'font-family': "Lato,'Helvetica Neue',Arial,Helvetica,sans-serif"}
    constructor(props) {
        super(props);
        this.state = {
            mediaIdsText: "",
            submittedMediaIdsText: "",
            textAreaValue: "",
            cacheTags: [],
            lastSubmittedCacheTags: [],
            textAreaError: false,
            invalidatedSuccess: false,
            invaidationFailed: false,
            loading: false,
            readyToSendrequest: false,
            openConfirm: false
        }
        MediumAdminDataProvider.init({ synapseCoreUrl: process.env.SYNAPSE_CORE_URL })
    }

    handleSaveClicked = () => {
        if (typeof this.props.handleSave === "function") {
            this.props.handleSave(this.state.name, this.state.data)
        }
    }

    toggleLoading = () => {
        this.setState(state => { return { loading: !state.loading } })
    }

    handleChange = (event) => {
        this.setState({
            textAreaValue: event.target.value,
            textAreaError: false,
            invalidatedSuccess: false,
            invaidationFailed: false,
            cacheTags: []
        })
    }

    handleSubmit = (event) => {
        const { textAreaValue } = this.state
        this.setState({ submittedMediaIdsText: textAreaValue }, () => { this.handleInvalidationRequest() })
    }

    handleInvalidate = (event) => {
        const { textAreaValue } = this.state
        this.setState({ submittedMediaIdsText: textAreaValue }, () => { this.handleInvalidationRequest() })
    }

    handleDelete = (event) => {
        const { textAreaValue } = this.state
        this.setState({ submittedMediaIdsText: textAreaValue }, () => { this.handleDeleteRequest() })
    }


    handleInvalidationRequest = () => {
        if (this.state.submittedMediaIdsText.length > 0) {
            this.setState({
                cacheTags: this.generateCacheTags(this.state.submittedMediaIdsText)
            }, () => { this.handleInvalidateApiRequest() })
        } else {
            this.setState({
                textAreaError: {
                    content: 'Please enter one or more Media IDs',
                    pointing: 'below',
                }, readyToSendrequest: false
            })
        }
    }

    handleDeleteRequest = () => {
        if (this.state.submittedMediaIdsText.length > 0) {
            this.setState({
                cacheTags: this.generateCacheTags(this.state.submittedMediaIdsText)
            }, () => { this.handleDeleteApiRequest() })
        } else {
            this.setState({
                textAreaError: {
                    content: 'Please enter one or more Media IDs',
                    pointing: 'below',
                }, readyToSendrequest: false
            })
        }
    }


    handleInvalidateApiRequest = () => {
        this.toggleLoading()
        MediumAdminDataProvider.invalidateCache(this.state.cacheTags)
            .then(resp => {
                if (resp.status >= 200 && resp.status < 300) {
                    this.setState({ invalidatedSuccess: true }, () => { this.toggleLoading() })
                } else {
                    this.setState({ invaidationFailed: true }, () => { this.toggleLoading() })
                }
            })
    }


    handleDeleteApiRequest = () => {
        this.toggleLoading()
        MediumAdminDataProvider.deleteCache(this.state.cacheTags)
            .then(resp => {
                if (resp.status >= 200 && resp.status < 300) {
                    this.setState({ invalidatedSuccess: true }, () => { this.toggleLoading() })
                } else {
                    this.setState({ invaidationFailed: true }, () => { this.toggleLoading() })
                }
            })
    }

    showConfirm = () => {
        this.setState({openConfirm: true})
    }

    handleCancel = () => {
        this.setState({openConfirm: false})
    }

    handleConfirm = () => {
        this.setState({openConfirm: false}, () => { this.handleDelete() })
    }

    generateCacheTags = (text) => {
        let mediaIds = this.parseMediaIds(text)
        if (mediaIds.length > 0) {
            return this.addCacheTagPrefix(mediaIds)
        } else {
            return []
        }
    }

    parseMediaIds = (text) => {
        let spaceDelimited = text.split('\n').map(element => element.split(' '))
        let flattenedParsedText = this.flattenArray(spaceDelimited)
        let commaDelimited = flattenedParsedText.map(element => element.split(','))
        let parsedText = this.flattenArray(commaDelimited)
        let mediaIds = parsedText.filter(id => id.length > 0).map(s => s.trim())
        return mediaIds
    }

    flattenArray = (nestedArray) => {
        return [].concat.apply([], nestedArray)
    }

    addCacheTagPrefix = (mediaIds) => {
        let environment = this.props.environment.toLowerCase()
        let prefix = `me-${environment}-`;
        let formattedMediaIds = mediaIds.map(id => prefix + id)
        return formattedMediaIds
    }

    clearTextArea = () => {
        this.setState({textAreaValue: '', invalidatedSuccess: false, invaidationFailed: false })
    }

    render() {
        return (
            <Form success={this.state.invalidatedSuccess} error={this.state.invaidationFailed} loading={this.state.loading}>
                <Header size="large" style={this.headerFontStyle}>Akamai Cache FastPurge</Header>
                <Form.TextArea
                    id='form-textarea-control-cache-invalidation'
                    name='mediaIdsText'
                    label='Media IDs'
                    value={this.state.textAreaValue}
                    placeholder='tru-west, live8ab3c384d83d8392ded8392de, me8ab3c384d83d8392ded8392de'
                    error={this.state.textAreaError}
                    onChange={this.handleChange}
                    style={{ minHeight: 225 }}
                />
                <Message
                    success
                    id='form-message-control-success'
                    header="Akamai Cache Tags affected:"
                    list={this.state.cacheTags}
                />
                <Message
                    error
                    id='form-message-control-error'
                    header="Akamai Fastpurge Failed"
                    content="Failed update Akamai Fastpurge API"
                />
                <Form.Group inline>
                    <Form.Button
                        primary
                        id='form-textarea-control-register-button'
                        onClick={this.handleInvalidate}
                        content='Invalidate Cache'
                    />
                    <Form.Button
                        color="red"
                        id='form-textarea-control-register-button'
                        onClick={this.showConfirm}
                        content='Delete Cache'
                    />
                    <Form.Button
                        id='form-textarea-control-clear-button'
                        content='Clear'
                        onClick={this.clearTextArea}
                    />
                </Form.Group>
                <Confirm
                    open={this.state.openConfirm}
                    header={`Akamai FastPurge`}
                    content={`You are about to delete cache tags.  Are you sure?`}
                    onCancel={this.handleCancel}
                    onConfirm={this.handleConfirm}
                    confirmButton='Delete'
                />
            </Form>
        )
    }
}

export default MediumAdminCacheInvalidation;
