import React, {Component} from "react";
import {Segment, List, ListContent} from "semantic-ui-react";

export default class MediumAuditReprocessConfirm extends Component {

    render() {
        let rows = this.props.rows;
        console.log(rows);
        let formattedRows = Object.keys(rows).map(function(rowKey) {
            return (
                <List.Item key={`${rows[rowKey].mediaId}-${rows[rowKey].lastUpdate.toLocaleString()}`}>
                    <List.Icon name="circle" verticalAlign="middle" />
                    <ListContent>
                        <List.Header>[{rows[rowKey].brand}] {rows[rowKey].mediaId}</List.Header>
                        <List.Description>Last Updated: {new Date(rows[rowKey].lastUpdate).toLocaleString()}</List.Description>
                    </ListContent>
                </List.Item>
            );
        });

        return (
            <Segment padded>
                <List divided relaxed>
                    {formattedRows}
                </List>
            </Segment>
        );
    }
}
