import React, {useState, useEffect} from "react";
import {Divider, Form, Select, Grid, Button, Message, Icon} from "semantic-ui-react";

export default function PlatformConfigEditorDetailView(props) {
    const [brandOptions, setBrandOptions] = useState([]);
    const [platformNames, setPlatformNames] = useState([]);
    const [currentlyAssignedPlatforms, setCurrentlyAssignedPlatforms] = useState([]);
    const [availablePlatforms, setAvailablePlatforms] = useState([]);
    const [defaultPlatforms, setDefaultPlatforms] = useState([]);
    const [selectedBrand, setSelectedBrand] = useState("");
    const [selectedProviderData, setSelectedProviderData] = useState({});
    const [providerHasBrand, setProviderHasBrand] = useState(false);
    const [messageContent, setMessageContent] = useState("");
    const [messageLevel, setMessageLevel] = useState(null);

    useEffect(function generateBrandOptions() {
        setBrandOptions(props.brands ? props.brands.map(brand => {
            return {key: brand.id, text: brand.displayName, value: brand.id};
        }) : []);
    }, [props.brands]);

    useEffect(function updateDefaultPlatforms() {
        if (selectedBrand && props.brands) {
            const brand = props.brands.find(brand => brand.id === selectedBrand);
            if (brand && brand.defaultPlatforms) {
                setDefaultPlatforms(brand.defaultPlatforms);
            } else {
                setDefaultPlatforms([]);
            }
        } else {
            setDefaultPlatforms([]);
        }
    }, [props.brands, selectedBrand]);

    const addDefaultPlatforms = () => {
        if (Array.isArray(defaultPlatforms)) {
            setCurrentlyAssignedPlatforms(Array.from(new Set(currentlyAssignedPlatforms.concat(defaultPlatforms))));
        }
    };

    useEffect(function getProviderInformation() {
        updateProviderInformation();
    }, [props.selectedProviderId]);

    useEffect(function updateProviderHasBrand() {
        if (selectedProviderData && selectedBrand && selectedProviderData.hasOwnProperty("brands") && selectedProviderData.brands.hasOwnProperty(selectedBrand)) {
            setProviderHasBrand(true);
        } else {
            setProviderHasBrand(false);
        }
    }, [JSON.stringify(selectedProviderData), selectedBrand]);

    useEffect(function setCurrentlyAssignedPlatformsFromProviderInfo() {
        if (selectedBrand && selectedProviderData && selectedProviderData.brands && selectedProviderData.brands.hasOwnProperty(selectedBrand) && selectedProviderData.brands[selectedBrand].hasOwnProperty("platforms")) {
            const platforms = selectedProviderData.brands[selectedBrand].platforms.map(platform => platform.id);
            setCurrentlyAssignedPlatforms(platforms);
        } else {
            setCurrentlyAssignedPlatforms([]);
        }
    }, [JSON.stringify(selectedProviderData), selectedBrand]);

    const updateProviderInformation = () => {
        if (props.selectedProviderId) {
            props.TVEManagerDataProvider.getProviderById(props.selectedProviderId).then(response => {
                setSelectedProviderData(response);
            });
        }
    };

    const addBrandClicked = () => {
        if (selectedBrand && props.selectedProviderId) {
            props.TVEManagerDataProvider.addBrand(selectedBrand, props.selectedProviderId).then(response => {
                if (response.message) {
                    setMessageContent(response.message);
                    setMessageLevel("positive");
                } else if (response.error) {
                    setMessageContent(response.error.toString());
                    setMessageLevel("negative");
                }
                updateProviderInformation();
                console.log(response);
            }).catch(error => {
                console.error(error);
            });
        }
    };

    const removeBrandClicked = () => {
        if (selectedBrand && props.selectedProviderId) {
            props.TVEManagerDataProvider.removeBrand(selectedBrand, props.selectedProviderId).then(response => {
                console.log(response);
                if (response.message) {
                    setMessageContent(response.message);
                    setMessageLevel("positive");
                } else if (response.error) {
                    setMessageContent(response.error.toString());
                    setMessageLevel("negative");
                }
                updateProviderInformation();
            }).catch(error => {
                console.error(error);
            });
        }
    };

    const stageChangesClicked = () => {
        if (selectedBrand && props.selectedProviderId && providerHasBrand) {
            const platforms = props.platforms.filter(platform => currentlyAssignedPlatforms.includes(platform.id));
            console.log("(PlatformConfigEditorDetailView.stageChangesClicked) platforms: ", props.platforms, platforms);
            props.TVEManagerDataProvider.updateProvider(selectedBrand, props.selectedProviderId, platforms).then(response => {
                console.log(response);
                if (response.message) {
                    setMessageContent(response.message);
                    setMessageLevel("positive");
                } else if (response.error) {
                    setMessageContent(response.error.toString());
                    setMessageLevel("negative");
                }
            }).catch(error => {
                console.log(error);
                setMessageContent(error.error);
                setMessageLevel("negative");
            });
        }
    };

    useEffect(function updatePlatformNames() {
        if (Array.isArray(props.platforms)) {
            setPlatformNames(props.platforms.map(platform => platform.id));
        } else {
            setPlatformNames([]);
        }
    }, [props.platforms]);

    useEffect(function setAvailablePlatformsOnCurrentlyAssignedPlatformsUpdate() {
        if (platformNames) {
            const platforms = platformNames.filter(platform => !currentlyAssignedPlatforms.includes(platform));
            setAvailablePlatforms(platforms);
        } else {
            setAvailablePlatforms([]);
        }
    }, [platformNames, currentlyAssignedPlatforms]);

    return (
        <Grid>
            <Grid.Column width={16}>
                <Message hidden={messageLevel == null || messageContent.length < 1} positive={messageLevel === "positive"} negative={messageLevel === "negative"} icon>
                    <Icon name={messageLevel === "negative" ? "x" : messageLevel === "positive" ? "check" : "chevron right"} />
                    {messageContent}
                </Message>
                <Form>
                    <Form.Field
                        label="Brand"
                        control={Select}
                        options={brandOptions}
                        value={selectedBrand}
                        onChange={(event, {value}) => setSelectedBrand(value)}
                    />
                    {
                        selectedBrand && props.selectedProviderId ?
                            <Grid.Column width={16}>
                                <Grid.Row>
                                    <Form.Group widths="equal">
                                        <Form.Field>
                                            <label>&nbsp;</label>
                                            <Button primary fluid disabled={!providerHasBrand} onClick={stageChangesClicked}>Stage Changes</Button>
                                        </Form.Field>
                                        <Form.Field>
                                            <label>&nbsp;</label>
                                            <Button secondary fluid onClick={addDefaultPlatforms}>Add Default Platforms to Brand</Button>
                                        </Form.Field>
                                    </Form.Group>
                                </Grid.Row>
                                <Grid textAlign="center">
                                    <Grid.Row>
                                        <Grid.Column>
                                            <Divider horizontal>Default Platforms</Divider>
                                            {
                                                defaultPlatforms.length > 0 ?
                                                    defaultPlatforms.map(platform => <Button key={`default-platform-${platform}`} circular color="lightgrey">{platform}</Button>) :
                                                    <Message>The brand {selectedBrand} has no default platforms configured for {props.selectedProviderId}.</Message>
                                            }
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <Divider horizontal>Currently Assigned Platforms</Divider>
                                            {
                                                currentlyAssignedPlatforms.length > 0 ?
                                                    currentlyAssignedPlatforms.map(platform =>
                                                        <Button
                                                            key={`assigned-platform-${platform}`}
                                                            circular
                                                            primary
                                                            onClick={() => setCurrentlyAssignedPlatforms(currentlyAssignedPlatforms.filter(assignedPlatform => assignedPlatform !== platform))}
                                                        >
                                                            <Icon name="minus"/>{platform}
                                                        </Button>) :
                                                    <Message>The MVPD {props.selectedProviderId} currently has no selected platforms for the brand {selectedBrand}.</Message>
                                            }
                                            <Divider horizontal>Remaining Platforms</Divider>
                                            {
                                                availablePlatforms.length > 0 ?
                                                    availablePlatforms.map(platform =>
                                                        <Button
                                                            key={`available-platform-${platform}`}
                                                            circular
                                                            color="red"
                                                            onClick={() => setCurrentlyAssignedPlatforms(currentlyAssignedPlatforms.concat(platform))}
                                                        >
                                                            <Icon name="plus"/>{platform}
                                                        </Button>) :
                                                    <Message>The MVPD {props.selectedProviderId} has no remaining platforms to add.</Message>
                                            }
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                                <br />
                                <Divider />
                                <Form.Group widths="equal">
                                    <Form.Field>
                                        <label>&nbsp;</label>
                                        <Button color="green" fluid disabled={providerHasBrand} onClick={addBrandClicked}>Add MVPD to Brand</Button>
                                    </Form.Field>
                                    <Form.Field>
                                        <label>&nbsp;</label>
                                        <Button color="red" fluid disabled={!providerHasBrand} onClick={removeBrandClicked}>Remove MVPD from Brand</Button>
                                    </Form.Field>
                                </Form.Group>
                            </Grid.Column> :
                            <Message>Please select a provider and a brand to continue.</Message>
                    }
                </Form>
            </Grid.Column>
        </Grid>
    );
};
