import React, {useState, useEffect} from "react";
import {Grid, Header} from "semantic-ui-react";
import ReactTable from "react-table-v6";
import PlatformConfigEditorDetailView from "./PlatformConfigEditorDetailView";

export default function PlatformConfigEditorMasterDetailView(props) {
    const [providers, setProviders] = useState([]);
    const [platforms, setPlatforms] = useState([]);
    const [selectedProviderId, setSelectedProviderId] = useState("");

    useEffect(function initializeState() {
        props.TVEManagerDataProvider.getProviders().then(response => {
            setProviders(Array.isArray(response.providers) ? response.providers : []);
        });
        props.TVEManagerDataProvider.getPlatforms().then(response => {
            setPlatforms(Array.isArray(response.platforms) ? response.platforms : []);
        });
    }, []);

    return (
        <Grid>
            <Grid.Row>
                <Grid.Column width={7} className="masterContainer">
                    <Grid.Row>
                        <ReactTable
                            data={providers}
                            columns={[
                                {Header: "Provider ID", accessor: "id"},
                                {Header: "Display Name", accessor: "displayName"}
                            ]}
                            filterable
                            defaultFilterMethod={(filter, rows) => rows[filter.id].toString().toLowerCase().includes(filter.value.toString().toLowerCase())}
                            getTrProps={(state, rowInfo, column, instance) => {
                                return {
                                    onClick(event, handleOriginal) {
                                        setSelectedProviderId(rowInfo.row.id);
                                    },
                                    style: {
                                        background: rowInfo && rowInfo.row && selectedProviderId === rowInfo.row.id ? "rgba(65, 83, 175, .5)" : ""
                                    }
                                };
                            }}
                            className="-striped -highlight"
                            defaultPageSize={16}
                        />
                    </Grid.Row>
                </Grid.Column>
                <Grid.Column width={9} className="detailsContainer">
                    <PlatformConfigEditorDetailView
                        selectedProviderId={selectedProviderId}
                        brands={props.brands}
                        platforms={platforms}
                        TVEManagerDataProvider={props.TVEManagerDataProvider}
                    />
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};
