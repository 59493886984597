import React from "react";
import {Grid, Header, Form, Message, Confirm, Label} from "semantic-ui-react";
import MediumAdminDataProvider from "../../../Services/MediumAdminProvider";


class MediumAdminSSAIToggleButton extends React.Component {
    headerFontStyle = {fontFamily: "Lato,'Helvetica Neue',Arial,Helvetica,sans-serif", marginLeft: '0.6em'}
    constructor(props) {
        super(props);
        this.state = {
            ssaiEnabled: props.ssaiEnabled, // Initialize button to false
            getMetadata: props.getMetadata,
            toggleSuccess: true,
            toggleFailed: false,
            openEnableConfirm: false,
            openDisableConfirm: false,
            loading: false
        }
    }

    toggleLoading = () => {
        this.setState(state => { return { loading: !state.loading } })
    }

    handleConfirm = () => {
        this.state.getMetadata();
        this.toggleLoading();
    }

    handleSSAIEnable = () => {
        this.toggleLoading();

        MediumAdminDataProvider.enableSSAI()
            .then(resp => {
                if (resp) {
                    this.setState({
                        ssaiEnabled: true,
                        openEnableConfirm: false
                    }, () => { this.toggleLoading() })
                } else {
                    this.setState({
                        openEnableConfirm: false
                    }, () => { this.handleConfirm() })
                }
            })

    }

    handleSSAIDisable = () => {
        this.toggleLoading();

        MediumAdminDataProvider.disableSSAI()
            .then(resp => {
                if (resp) {
                    this.setState({
                        ssaiEnabled: false,
                        openDisableConfirm: false
                    }, () => { this.toggleLoading() })
                } else {
                    this.setState({
                        openDisableConfirm: false
                    }, () => { this.handleConfirm() })
                }
            })
    }


    handleSSAIEnableConfirmation = () => {
        this.setState({openEnableConfirm: true})
    }

    handleSSAIDisableConfirmation = () => {
        this.setState({openDisableConfirm: true})
    }

    handleSSAICancel = () => {
        this.setState({
            openEnableConfirm: false,
            openDisableConfirm: false,
        })
    }

    render() {
        return (
            <Form success={this.state.toggleSuccess} error={this.state.toggleFailed} loading={this.state.loading} >
                <Header size="large" style={this.headerFontStyle}>SSAI Management</Header>
                <Message
                    info
                    hidden={!this.state.ssaiEnabled}
                    id='form-message-ssai-enabled'
                    header="SSAI Status: Active"
                    content="SSAI is currently enabled."
                />
                <Message
                    negative
                    hidden={this.state.ssaiEnabled}
                    id='form-message-ssai-disabled'
                    header="SSAI Status: Disabled"
                    content="SSAI is currently disabled."
                />
                <Form.Group inline>
                    <Form.Button
                        primary
                        disabled={this.state.ssaiEnabled}
                        id='form-ssai-toggle-button-enable'
                        content='Enable'
                        onClick={this.handleSSAIEnableConfirmation}
                    />
                    <Form.Button
                        primary
                        negative
                        disabled={!this.state.ssaiEnabled}
                        id='form-ssai-toggle-button-disable'
                        content='Disable'
                        onClick={this.handleSSAIDisableConfirmation}
                    />
                </Form.Group>
                <Confirm
                    open={this.state.openEnableConfirm}
                    header={`Enable SSAI`}
                    content={`You are about to enable SSAI in Medium.  Are you sure?`}
                    onCancel={this.handleSSAICancel}
                    onConfirm={this.handleSSAIEnable}
                    confirmButton='Enable'
                />
                <Confirm
                    open={this.state.openDisableConfirm}
                    header={`Disable SSAI`}
                    content={`You are about to disable SSAI in Medium.  Are you sure?`}
                    onCancel={this.handleSSAICancel}
                    onConfirm={this.handleSSAIDisable}
                    confirmButton='Disable'
                />
            </Form>
        )
    }
}

export default MediumAdminSSAIToggleButton
