import React, {useState, useEffect} from "react";
import {Grid, Header} from "semantic-ui-react";
import ReactTable from "react-table-v6";
import GroupConfigEditorDetailView from "./GroupConfigEditorDetailView";

export default function GroupConfigEditorMasterDetailView(props) {
    const [groups, setGroups] = useState([]);
    const [selectedGroupId, setSelectedGroupId] = useState("");

    useEffect(function initializeState() {
        props.TVEManagerDataProvider.getGroups().then(response => {
            console.log(response);
            setGroups(Array.isArray(response.groups) ? response.groups : []);
        });
    }, []);

    return (
        <Grid>
            <Grid.Row>
                <Grid.Column width={7} className="masterContainer">
                    <Grid.Row>
                        <ReactTable
                            data={groups}
                            columns={[
                                {Header: "Group Name", accessor: "group"}
                            ]}
                            filterable
                            defaultFilterMethod={(filter, rows) => rows[filter.id].toString().toLowerCase().includes(filter.value.toString().toLowerCase())}
                            getTrProps={(state, rowInfo, column, instance) => {
                                return {
                                    onClick(event, handleOriginal) {
                                        setSelectedGroupId(rowInfo.row.group);
                                    },
                                    style: {
                                        background: rowInfo && rowInfo.row && selectedGroupId === rowInfo.row.group ? "rgba(65, 83, 175, .5)" : ""
                                    }
                                };
                            }}
                            className="-striped -highlight"
                            defaultPageSize={16}
                        />
                    </Grid.Row>
                </Grid.Column>
                <Grid.Column width={9} className="detailsContainer">
                    <GroupConfigEditorDetailView
                        selectedGroupId={selectedGroupId}
                        brands={props.brands}
                        TVEManagerDataProvider={props.TVEManagerDataProvider}
                    />
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};
