import React from "react";
import {Header, Message, Form } from "semantic-ui-react";
import MediumAdminDataProvider from "../../../Services/MediumAdminProvider";

import 'jsoneditor/dist/jsoneditor.css'



class MediumAdminRegisterForm extends React.Component {
    headerFontStyle = {fontFamily: "Lato,'Helvetica Neue',Arial,Helvetica,sans-serif"}
    registrationExample = {foreignId: "ExampleID",  brand: "xxx", output: [{files: ["....."]}]}

    constructor(props) {
        super(props);
        this.state = {
            ingestJsonText: "",
            submittedIngestJsonText: "",
            loading: false,
            textAreaError: false,
            registerFailed: false,
            registeredSuccess: false,
            readyToSendRequest: false
        }
    }

    toggleLoading = () => {
      this.setState(state => {return {loading: !state.loading}})
    }

    handleChange = (event) => {
      this.setState({ ingestJsonText: event.target.value, textAreaError: false, registeredSuccess: false, registerFailed: false})
    }

    handleSubmit = (event) => {
      this.setState( state =>
        {
          return { submittedIngestJsonText: state.ingestJsonText }
        }, () => {
          this.registerIngestJson()
        })

    }

    registerIngestJson = () => {
      this.checkIsValidJson(this.state.submittedIngestJsonText)
    }

    sendRegistrationRequest = () => {
      if(this.state.readyToSendRequest) {
        this.toggleLoading()
        MediumAdminDataProvider.registerMediaJson(this.state.submittedIngestJsonText)
          .then(resp => {
            this.handleRegistrationResponse(resp)
          })
      }
    }

    checkIsValidJson = (text) => {
      if(this.isValidJson(text)) {
        this.setState({textAreaError: false, readyToSendRequest: true}, () => { this.sendRegistrationRequest() })
      } else {
        this.setState({textAreaError: {
          content: 'Please enter valid JSON',
          pointing: 'below',
        }, readyToSendrequest: false})
      }
    }

    handleRegistrationResponse = (resp) => {
      if(resp) {
        this.setState({registeredSuccess: true}, () => { this.toggleLoading() })
      } else {
        // something went wrong
        this.setState({registerFailed: true}, () => { this.toggleLoading() })
      }
    }

    handleRegistration = (text) => {
        MediumAdminDataProvider.registerMediaJson(text)
          .then(resp => {
              this.handleRegistrationResponse(resp)
          })
    }

    clearTextArea = () => {
      this.setState({ ingestJsonText: "", textAreaError: false, registeredSuccess: false, registerFailed: false})
    }

    isValidJson = (text) => {
        try {
            JSON.parse(text)
        } catch(error) {
            return false
        }
        return true
    }

    render() {
        return(
              <Form success={this.state.registeredSuccess} error={this.state.registerFailed} loading={this.state.loading} >
                <Header size="large" style={this.headerFontStyle}>Media Asset Registration</Header>
                  <Form.TextArea
                      id='form-textarea-control-media-json'
                      name='ingestJsonText'
                      label='Media Ingest Payload'
                      placeholder={JSON.stringify(this.registrationExample, null, 2)}
                      value={this.state.ingestJsonText}
                      error={this.state.textAreaError}
                      onChange={this.handleChange}
                      style={{ minHeight: 225 }}
                  />
                  <Message
                    success
                    id='form-message-control-success'
                    header="Registration Complete"
                    content="Medium successfully processed ingest payload"
                  />
                  <Message
                    error
                    id='form-message-control-success'
                    header="Registration Failed"
                    content="Medium unable to register ingest payload"
                  />
                  <Form.Group inline>
                    <Form.Button
                        primary
                        id='form-textarea-control-register-button'
                        content='Register'
                        onClick={this.handleSubmit}
                    />
                    <Form.Button
                        id='form-textarea-control-clear-button'
                        content='Clear'
                        onClick={this.clearTextArea}
                    />
                  </Form.Group>
              </Form>
         )
    }
}

export default MediumAdminRegisterForm
