import React, {useState} from "react";
import { Grid, Header, Message, Form } from "semantic-ui-react";
import MediumAdminDataProvider from "../../../Services/MediumAdminProvider";

import 'jsoneditor/dist/jsoneditor.css'


export default function (props) {
    const [ingestJsonText, setIngestJsonText] = useState("");
    const [loading, setLoading] = useState(false);
    const [textAreaError, setTextAreaError] = useState(false);
    const [registerFailed, setRegisterFailed] = useState(false);
    const [registeredSuccess, setRegisteredSuccess] = useState(false);
    const [readyToSendRequest, setReadyToSendRequest] = useState(false);

    const sendRegistrationRequest = () => {
        console.log("MediumAssetRegistration.sendRegistrationRequest: sending registration request: ", ingestJsonText);

        if (readyToSendRequest) {
            setLoading(true);
            MediumAdminDataProvider.registerMediaJson(ingestJsonText).then(resp => {
                if (resp) {
                    setRegisteredSuccess(true);
                } else {
                    setRegisterFailed(true);
                    // something went wrong
                }
            }).finally(() => {
                setLoading(false);
            });
        }
    };

    const clearTextArea = () => {
        setIngestJsonText("");
        setTextAreaError(false);
        setRegisteredSuccess(false);
        setRegisterFailed(false);
    }

    const isValidJson = (text) => {
        try {
            JSON.parse(text);
        } catch(error) {
            return false;
        }
        return true;
    }

    const checkIsValidJson = () => {
        console.log("MediumAssetRegistration.checkIsValidJson: checking if the following is valid: ", ingestJsonText);
        if(isValidJson(ingestJsonText)) {
            setTextAreaError(false);
            setReadyToSendRequest(true);
            setLoading(true);
            MediumAdminDataProvider.registerMediaJson(ingestJsonText).then(resp => {
                if (resp) {
                    setRegisteredSuccess(true);
                } else {
                    setRegisterFailed(true);
                    // something went wrong
                }
            }).finally(() => {
                setLoading(false);
            });
        } else {
            setTextAreaError({
                content: "Please enter valid JSON",
                pointing: "below"
            });
            setReadyToSendRequest(false);
        }
    }

    return(
        <Grid className="masterContainer">
            <Grid.Row>
                <Grid.Column width={16}>
                    <Form success={registeredSuccess} error={registerFailed} loading={loading} >
                        <Header size="large">Media Asset Registration</Header>
                        <Form.TextArea
                            label='Media Ingest Payload'
                            placeholder={JSON.stringify({foreignId: "ExampleID",  brand: "xxx", output: [{files: ["....."]}]}, null, 2)}
                            value={ingestJsonText}
                            error={textAreaError}
                            onChange={
                                (_, {value}) => {
                                    setIngestJsonText(value);
                                    setTextAreaError(false);
                                    setRegisteredSuccess(false);
                                    setRegisterFailed(false);
                                }
                            }
                            style={{ minHeight: 225 }}
                        />
                        <Message
                            success
                            header="Registration Complete"
                            content="Medium successfully processed ingest payload"
                        />
                        <Message
                            error
                            header="Registration Failed"
                            content="Medium unable to register ingest payload"
                        />
                        <Form.Group inline>
                            <Form.Button
                                primary
                                content="Register"
                                onClick={
                                    () => {
                                        checkIsValidJson();
                                    }
                                }
                            />
                            <Form.Button
                                content="Clear"
                                onClick={clearTextArea}
                            />
                        </Form.Group>
                    </Form>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
}
