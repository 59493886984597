import React from "react";
import { Header, Grid, Menu, Loader, Dimmer } from "semantic-ui-react";
import MediumAdminJsonEditor from "./MediumAdminJsonEditor";
import 'jsoneditor/dist/jsoneditor.css';


class MediumAdminMetadataForm extends React.Component {
    headerFontStyle = {fontFamily: "Lato,'Helvetica Neue',Arial,Helvetica,sans-serif", marginLeft: '0.6em'}
    constructor(props) {
        super(props);
        this.state = {
            activeItem: 'appIdMappings', // Object.keys(this.props.metadata)[0],
            lastActiveItem: 'appIdMappings',
            currentEditorText: {},
            lastEditorText: {},
            menuClicked: false,
            initialLoad: true
        }
    }

    handleItemClick = (e, { name }) => {
        this.setState({ activeItem: name }, () => { this.handleChangeEditorText() })
    }

    toggleMenuClicked = () => {
        this.setState(state => {return {menuClicked: !state.menuClicked}})
    }

    disableInitialLoad = () => {
        if(this.state.initialLoad) { this.setState({initialLoad: false}) }
    }


    toggleLoading = () => {
        this.setState(state => {return {loading: !state.loading}})
    }

    handleReset = () => {
        this.setState(state => {return {currentEditorText: state.lastEditorText}})
    }

    handleChangeEditorText = () => {
        let mapping = this.state.activeItem
        let currentMetadata = {};

        if(mapping.length) {
            currentMetadata = this.props.metadata[mapping]
        }

        this.setState({
            currentEditorText: currentMetadata,
            lastEditorText: currentMetadata
        }, () => { this.toggleMenuClicked() })
    }

    initializeActiveItem = () => {
        if(this.props.metadataLoading && this.state.activeItem === '') {
            let initialActiveItem = Object.keys(this.props.metadata)[0];
            console.log(`initializeActiveitem: ${this.props.activeItem}`)
            this.setState({activeItem: initialActiveItem}, () => {this.handleChangeEditorText()})
        }
    }

    componentDidMount = () => {
        this.initializeActiveItem()
    }


    render() {
        const menuItems = Object.keys(this.props.metadata).map(name =>
            <Menu.Item
                name={name}
                content={name}
                key={name}
                active={this.state.activeItem === name}
                onClick={this.handleItemClick}
            />)

        return (
            <Grid>
                <Dimmer inverted active={this.props.metadataLoading}>
                    <Loader >{this.props.metadataLoadingText}</Loader>
                </Dimmer>
                <Grid.Row>
                    <Header size="large" style={this.headerFontStyle}>Metadata Editor</Header>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={5}>
                        <Menu fluid vertical tabular>
                            {menuItems}
                        </Menu>
                    </Grid.Column>
                    <MediumAdminJsonEditor
                        currentEditorText={this.state.currentEditorText}
                        lastEditorText={this.state.lastEditorText}
                        activeItem={this.state.activeItem}
                        environment={this.props.environment}
                        setMetadata={this.props.setMetadata}
                        lastNodeValue={this.state.currentEditorText}
                        toggleMenuClicked={this.toggleMenuClicked}
                        menuClicked={this.state.menuClicked}
                        disableInitialLoad={this.disableInitialLoad}
                        initialLoad={this.state.initialLoad} />
                </Grid.Row>
            </Grid>
        )
    }
}

export default MediumAdminMetadataForm
