import React, {useMemo} from "react";
import {Segment, Grid, Tab, Button} from "semantic-ui-react";
import MediumAuditMessageView from "./MediumAuditMessageView";

export default function MediumAuditEntry(props) {
    const messageType = useMemo(() => {
        return props.source === "ODT" ? "ODT MediaJSON Ingest" : "Ingest Payload"
    }, [props.source]);

    const message = useMemo(() => {
        try {
            return JSON.stringify(JSON.parse(atob(props.message)), null, 4);
        } catch (e) {
            console.error("Unexpected error setting the message from payload", props.message, e);
            return props.message;
        }
    }, [props.message]);

    const copyToClipboard = payload => {
        navigator.clipboard.writeText(payload).then(() => {
            props.toast("Copied", `You have copied the ${messageType} message to your clipboard.`, "success");
        });
    };

    return (
        <Grid>
            <Grid.Column width={16}>
                <Tab
                    fluid
                    styled
                    defaultActiveIndex={[0]}
                    exclusive={false}
                    panes={[
                        {
                            menuItem: messageType,
                            render: () =>  (
                                <Segment padded="very">
                                    <Button floated="right" onClick={() => copyToClipboard(message)}>Copy {messageType}</Button>
                                    <br />
                                    <MediumAuditMessageView message={props.message} />
                                </Segment>
                            )
                        }
                    ]}
                />
            </Grid.Column>
        </Grid>
    );
};
