import React, {Component} from "react";

export default class MediumAuditMessageView extends Component {

    syntaxHighlight = (jsonText) => {
        jsonText = JSON.stringify(jsonText, null, 2).replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;");
        return jsonText.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g, function (match) {
            let cls = "json-number";
            if (/^"/.test(match)) {
                if (/:$/.test(match)) {
                    cls = "json-key";
                } else {
                    cls = "json-string";
                }
            } else if (/true|false/.test(match)) {
                cls = "json-boolean";
            } else if (/null/.test(match)) {
                cls = "json-null";
            }
            return `<span class="${cls}">${match}</span>`;
        });
    }

    render() {
        let ingestJson = window.atob(this.props.message);
        let displayJsonObj = {};
        if(ingestJson.length > 0) {
            displayJsonObj = JSON.parse(ingestJson);
        }

        let highlightedJson = this.syntaxHighlight(displayJsonObj)

        return (
            <div className="json-block-dark">
                <pre dangerouslySetInnerHTML={{__html:highlightedJson}}></pre>
            </div>
        );
    }
}
