import React from "react";
import { Grid, Button, Confirm } from "semantic-ui-react";
import JSONEditor from 'jsoneditor'
import 'jsoneditor/dist/jsoneditor.css'


class MediumAdminJsonEditor extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            lastActiveItem: 'appIdMappings',
            currentEditorText: {},
            lastEditorText: {},
            openConfirm: false
        }
    }

    handleReset = () => {
        this.jsoneditor.set(this.props.lastEditorText)
    }
    
    handleConfirm = () => {
        this.setState({openConfirm: false}, () => { this.handleSubmit() })
    }

    handleCancel = () => {
        this.setState({openConfirm: false})
    }   

    handleSubmit = () => {
        let editorText = this.jsoneditor.get()
        console.log(`calling updateFunc with ${this.props.activeItem}, and ${JSON.stringify(editorText)}`)
        this.props.setMetadata(this.props.activeItem, this.props.lastEditorText, editorText)
        this.setState({openConfirm: false})
    }

    showConfirm = () => {
        this.setState({openConfirm: true})
    }

    componentDidMount = () => {
        console.log(`(componentDidMount) Started.`)
        const options = {
            mode: 'code'
        }
        this.jsoneditor = new JSONEditor(this.container, options)
        if(this.props.initialLoad) {
            this.jsoneditor.set(this.props.currentEditorText)
            this.props.disableInitialLoad()
        }
    }

    updateCurrentEditorText = () => {
        if(this.jsoneditor && this.props.menuClicked) {
            
            this.props.toggleMenuClicked()
            this.jsoneditor.set(this.props.currentEditorText)
        }
    }
        

    render() {
        this.updateCurrentEditorText()
        return (
            <Grid.Column width={11}>
                <Grid.Row>
                    <div style={{ height: '23em' }} ref={elem => this.container = elem} />
                </Grid.Row>
                <Grid.Row></Grid.Row>
                <Grid.Row style={{'padding-top': '1em'}}>
                        <Button primary onClick={this.showConfirm} style={{'margin-right': '1em'}}>Submit</Button>
                        <Button secondary onClick={this.handleReset}>Reset</Button>
                </Grid.Row>
                <Confirm
                    open={this.state.openConfirm}
                    header={`Medium Metadata  ·  ${this.props.environment}`}
                    content={`You are updating metadata for "${this.props.activeItem}" node.  Are you sure?`}
                    onCancel={this.handleCancel}
                    onConfirm={this.handleConfirm}
                    />
            </Grid.Column>
        )
    }
}

export default MediumAdminJsonEditor