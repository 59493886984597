import React, {useEffect, useState} from "react";
import {Button, Form, Grid, Icon, Message, Select} from "semantic-ui-react";

export default function GroupConfigEditorDetailView(props) {
    const [brandOptions, setBrandOptions] = useState([]);
    const [selectedBrand, setSelectedBrand] = useState("");
    const [messageContent, setMessageContent] = useState("");
    const [messageLevel, setMessageLevel] = useState(null);

    useEffect(function generateBrandOptions() {
        setBrandOptions(props.brands ? props.brands.map(brand => {
            return {key: brand.id, text: brand.displayName, value: brand.id};
        }) : []);
    }, [props.brands]);

    const addBrandClicked = () => {
        if (selectedBrand && props.selectedGroupId) {
            props.TVEManagerDataProvider.addBrandToGroup(selectedBrand, props.selectedGroupId).then(response => {
                if (response.message) {
                    setMessageContent(response.message);
                    setMessageLevel("positive");
                } else if (response.error) {
                    setMessageContent(response.error.toString());
                    setMessageLevel("negative");
                }
                console.log(response);
            }).catch(error => {
                console.error(error);
            });
        }
    };

    return (
        <Grid>
            <Grid.Column width={16}>
                <Message hidden={messageLevel == null || messageContent.length < 1} positive={messageLevel === "positive"} negative={messageLevel === "negative"} icon>
                    <Icon name={messageLevel === "negative" ? "x" : messageLevel === "positive" ? "check" : "chevron right"} />
                    {messageContent}
                </Message>
                <Form>
                    <Form.Field
                        label="Brand"
                        control={Select}
                        options={brandOptions}
                        value={selectedBrand}
                        onChange={(event, {value}) => setSelectedBrand(value)}
                    />
                    {
                        selectedBrand && props.selectedGroupId ?
                            <Grid.Column width={16}>
                                <Form.Group widths="equal">
                                    <Form.Field>
                                        <label>&nbsp;</label>
                                        <Button color="green" fluid onClick={addBrandClicked}>Add Brand to Group</Button>
                                    </Form.Field>
                                </Form.Group>
                            </Grid.Column> :
                            <Message>Please select a group and a brand to continue.</Message>
                    }
                </Form>
            </Grid.Column>
        </Grid>
    );
};
