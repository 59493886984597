import React, { Component } from "react";
import MediumAuditDataProvider from "../../../Services/MediumAuditDataProvider";
import MediumAdminDataProvider from "../../../Services/MediumAdminProvider";
import MediumAuditReprocessConfirm from "./MediumAuditReprocessConfirm";
import ARADataProvider from "../../../Services/ARADataProvider"
import OriginCDNMapperProvider  from "../../../Services/OriginCDNMapperDataProvider";
import MediumAuditEntry from "./MediumAuditEntry";
import {Header, Grid, Icon, Input, Button, Divider, Confirm, Form, Select} from "semantic-ui-react";
import ReactTable from "react-table-v6";
import selectTableHOC from "react-table-v6/lib/hoc/selectTable";
import treeTableHOC from "react-table-v6/lib/hoc/treeTable";
import "date-fns";
import "./styles.css";
import DateFnsUtils from "@date-io/date-fns";
import {MuiPickersUtilsProvider, DateTimePicker} from "@material-ui/pickers";
import ContingentButton from "../../ContingentButton";
const jwt = require("jsonwebtoken");


const SelectTable = selectTableHOC(treeTableHOC(ReactTable))

const oneDayInMilliseconds = 24 * 60 * 60 * 1000;
const oneWeekInMilliseconds = oneDayInMilliseconds * 7;
const oneMonthInMilliseconds = oneWeekInMilliseconds * 4;

MediumAuditDataProvider.init({ baseURL: process.env.MEDIUM_URL });
MediumAdminDataProvider.init({ baseURL: process.env.MEDIUM_URL, synapseCoreUrl: process.env.SYNAPSE_CORE_URL });
OriginCDNMapperProvider.init({baseURL: process.env.ORIGIN_CDN_MAPPER_URL});
ARADataProvider.init({ baseURL: process.env.PROD_ARA});

class MediumAuditMasterDetailView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            mediaId: "",
            foreignId: "",
            selectedMediaId: "",
            selectedForeignId: "",
            selectedBrand: "",
            message: "",
            startTime: new Date().getTime() - oneMonthInMilliseconds,
            endTime: new Date().getTime() + oneWeekInMilliseconds,
            selectedRow: {},
            selectedRowBrands: [],
            selectedKey: null,
            auditData: [],
            isLoading: true,
            selectedV1AppId: "all",
            selectedV2AppId: "",
            appIds: [],
            v1AppIds: [],
            validBrands: [],
            loadingText: "Loading...",
            reprocessButtonEnabled: false,
            isReprocessing: false,
            searchInProgress: false,
            showReprocessConfirm: false,
            selectedRows: {},
            userCanEditAll: false,
            userCanViewAll: false,
            reprocessableBrands: [],
            viewableBrands: []
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (JSON.stringify(prevState.selectedRows) !== JSON.stringify(this.state.selectedRows)) {
            console.log("MediumAuditMasterDetailView.componentDidUpdate: selectedRows has been updated.");
            const updatedSelectedRowBrands = new Set();
            for (const row of Object.values(this.state.selectedRows)) {
                updatedSelectedRowBrands.add(row.brand.toLowerCase());
            }

            console.log("MediumAuditMasterDetailView.componentDidUpdate: selectedRows has been updated. Setting selectedRowBrands to: ", Array.from(updatedSelectedRowBrands));
            this.setState({selectedRowBrands: Array.from(updatedSelectedRowBrands)});
        }

        if (JSON.stringify(prevState.selectedRowBrands) !== JSON.stringify(this.state.selectedRowBrands)) {
            console.log("MediumAuditMasterDetailView.componentDidUpdate: selectedRowBrandss has been updated to: ", this.state.selectedRowBrands);
        }
    }

    async componentDidMount() {
        await this.refreshBrands();
        await this.getAppIds();
        await this.getData({start: this.state.startTime, end: this.state.endTime});
    }

    refreshBrands = async () => {
        this.setState({loadingText: "Loading brands..."}, async () => {
            let userCanViewAll = false;
            let userCanEditAll = false;
            const reprocessableBrandsSet = new Set();
            const viewableBrandsSet = new Set();

            for (const permission of this.props.userPermissions) {
                if (permission.urn === "urn:all:aspen-power") {
                    userCanEditAll = true;
                    userCanViewAll = true;
                    break;
                }

                const serviceMatches = permission.service === this.props.service;
                const moduleMatches = permission.module === this.props.module;
                const userIsEditor = permission.role === "editor";
                if (serviceMatches && moduleMatches) {
                    if (permission.scope === "all") {
                        userCanViewAll = true;

                        if (userIsEditor) {
                            userCanEditAll = true;
                            break;
                        }
                    } else {
                        viewableBrandsSet.add(permission.scope.toUpperCase());
                    }
                    if (userIsEditor) {
                        reprocessableBrandsSet.add(permission.scope.toUpperCase());
                    }
                }
            }

            if (userCanViewAll || userCanEditAll) {
                const allBrands = await this.getAllBrands();
                for (const brand of allBrands) {
                    viewableBrandsSet.add(brand.toUpperCase());
                    if (userCanEditAll) {
                        reprocessableBrandsSet.add(brand.toUpperCase());
                    }
                }
            }

            const viewableBrands = [];
            for (const brand of Array.from(viewableBrandsSet).sort()) {
                viewableBrands.push({key: brand, text: brand, value: brand.toUpperCase()});
            }
            const reprocessableBrands = Array.from(reprocessableBrandsSet).sort();

            console.log("MediumAuditMasterDetailView.refreshBrands: viewable brands: ", viewableBrands, "reprocessable brands: ", reprocessableBrands);

            this.setState({
                validBrands: viewableBrands,
                reprocessableBrands
            });
        });
    };

    getAllBrands = async () => {
        return await OriginCDNMapperProvider.getAll(this.props.user).then(data => {
            if (data.error) {
                this.setState({validBrands: []});
            } else {
                let uniqueBrands = new Set();
                if (Array.isArray(data)) {
                    for (let mapping of data) {
                        if (mapping.hasOwnProperty("brand")) {
                            uniqueBrands.add(mapping.brand.toUpperCase());
                        }
                    }
                }

                let brands = Array.from(uniqueBrands).sort();
                console.log("MediumAuditMasterDetailView.getAllBrands: got all brands from Origin-CDN Mapper: ", brands);
                return brands;
            }
        });
    }

    getAppIds = async () => {
        await ARADataProvider.getAll().then(data => {
            try {
                if (data.error) {
                    this.props.toast("Error", "There was an issue retrieving App IDs from ARA. This doesn't prevent searching by other fields.");
                    console.error(`Status Code: ${data.error.response.request.status}: ${data.error.response.data.error}`);
                    return;
                }
                const updatedAppIds = [];
                for (const appId of data) {
                    updatedAppIds.push({key: appId.appId, text: appId.appId, value: appId.token});
                }
                this.setState({selectedV1AppId: "", appIds: updatedAppIds});
            } catch(error) {
                console.error("MediumAuditMasterDetailView.getAppIds", error);
                this.props.toast("Error", "There was an issue retrieving App IDs from ARA. This doesn't prevent searching by other fields.");
            }
        });
    };

    getData = async criteriaMap => {
        await MediumAuditDataProvider.getData(criteriaMap).then(resp => {
            resp.json().then(auditData => {
                const updatedResults = [];
                const viewableBrands = [];
                for (const brand of this.state.validBrands) {
                    viewableBrands.push(brand.value);
                }

                for (let i = 0; i < auditData.results.length; i++) {
                    const auditMessage = Object.assign({}, auditData.results[i]);
                    if (viewableBrands.includes(auditMessage.brand)) {
                        if (auditMessage.hasOwnProperty("appId")) {
                            let v2AppId = auditMessage.appId;
                            let decodedMessage = jwt.decode(v2AppId);
                            auditMessage._id = i;
                            auditMessage.appId = decodedMessage.appId;
                        }

                        updatedResults.push(auditMessage);
                    }
                }
                this.setState({
                    auditData: updatedResults,
                    selectedRow: {},
                    isLoading: false,
                    searchInProgress: false,
                    loadingText: null
                });
            });
        });
    };

    performSearch = async () => {
        let mediaId = this.state.mediaId;
        let foreignId = this.state.foreignId;
        let startTime = this.state.startTime;
        let endTime = this.state.endTime;
        let appId = this.state.selectedV2AppId;
        let brand = this.state.selectedBrand;
        if (brand) {
            brand = brand.toUpperCase();
        } else {

        }

        let criteriaMap = {
            ...mediaId && {mediaId},
            ...foreignId && {foreignId},
            ...startTime && {start: startTime},
            ...endTime && {end: endTime},
            ...appId && {appId},
            ...brand && {brand}
        };

        await this.getData(criteriaMap);
    };

    handleSearchBtnClick = () => {
        this.setState({ searchInProgress: true, isLoading: true, loadingText: "Searching...", selectedRows: {}}, async () => {
            await this.performSearch();
        });
    };

    handleClear = () => {
        this.setState({
            mediaId: "",
            foreignId: "",
            appId: "",
            startTime: new Date().getTime() - oneMonthInMilliseconds,
            endTime: new Date().getTime() + oneWeekInMilliseconds,
            selectedV1AppId: "",
            selectedV2AppId: "",
            selectedBrand: "",
            isLoading: true,
            selectedRows: {},
            loadingText: "Clearing filters..."
        }, async () => {
            this.checkReprocessEnabled();
            await this.getData({start: this.state.startTime, end: this.state.endTime});
        });
    };


    handleReprocessResponse = resp => {
        this.setState({ selectedRows: {}, isReprocessing: false });
        const message =
            this.state.selectedMediaId && this.state.selectedForeignId ? `Media ID: ${this.state.selectedMediaId} | Foreign ID: ${this.state.selectedForeignId}` :
                this.state.foreignId ? `Foreign ID: ${this.state.selectedForeignId}` :
                this.state.selectedMediaId ? `Media ID: ${this.state.selectedMediaId}` : "";

        if (resp) {
            this.props.toast("Sucessfully Reprocessed", message, "success");
            this.setState({ isLoading: true, loadingText: "Refreshing" }, async () => {
                await this.getData({});
            });
        } else {
            this.props.toast("Unable To Reprocess", message, "error");
        }
    };

    sendRegistrationRequest = () => {
        let responses = [];
        for (const [key, item] of Object.entries(this.state.selectedRows)) {
            console.log("Row: ", key, item);
            let message = item.message;
            let registrationPayload = window.atob(message);
            console.log(registrationPayload);
            if (registrationPayload) {
                MediumAdminDataProvider.registerMediaJson(registrationPayload).then(resp => responses.push(resp));
            }
        }
        this.handleReprocessResponse(responses);
        this.setState({reprocessButtonEnabled: false});
    };

    handleAppIdSelect = (event, {key, value}) => {
        console.log(`HandleAppIdSelect ${event.target}, ${value}`);
        this.setState({
            selectedV1AppId: key,
            selectedV2AppId: value
        });
    };

    checkReprocessEnabled = () => {
        console.log("Checking if Reprocess enabled.");
        console.log("MediumAuditMasterDetailView.checkReprocessEnabled: selected rows: ", this.state.selectedRows);
        let areAllSelectedRowsReprocessable = true;
        for (const row of Object.values(this.state.selectedRows)) {
            areAllSelectedRowsReprocessable ||= this.state.reprocessableBrands.includes(row.brand);
            if (!areAllSelectedRowsReprocessable) {
                break;
            }
        }
        this.setState({reprocessButtonEnabled: this.state.selectedRows && Object.keys(this.state.selectedRows).length > 0 && areAllSelectedRowsReprocessable});
    };

    render() {
        let table = this;
        return (
            <Grid>
                <Grid.Row>
                    <Grid.Column width={4} className="masterContainer" style={{marginBottom: "0", maxHeight: "95vh", overflowY: "auto", overflowX: "hidden"}}>
                        <Header as="h1" icon textAlign="center">
                            <Icon name="medium m" textAlign="center" circular />
                            <Header.Content />
                        </Header>
                        <Divider horizontal>
                            <Header as="h4">
                                <Icon name="search" />
                                Search
                            </Header>
                        </Divider>
                        <Form widths="equal">
                            <Form.Field
                                label="Media ID"
                                control={Input}
                                search
                                allowAdditions
                                value={this.state.mediaId}
                                onChange={(event, {value}) => this.setState({mediaId: value})}
                            />
                            <Form.Field
                                label="Foreign ID"
                                control={Input}
                                search
                                allowAdditions
                                value={this.state.foreignId}
                                onChange={(event, {value}) => this.setState({foreignId: value})}
                            />
                            <Form.Field
                                label="App ID"
                                control={Select}
                                search
                                clearable
                                options={this.state.appIds}
                                value={this.state.selectedV1AppId}
                                onChange={this.handleAppIdSelect}
                            />
                            <Form.Field
                                label="Brand"
                                control={Select}
                                search
                                clearable
                                allowAdditions={this.state.userCanViewAll || this.state.userCanEditAll}
                                options={this.state.validBrands}
                                value={this.state.selectedBrand}
                                onChange={(event, {value}) => this.setState({selectedBrand: value})}
                                onAddItem={(event, {value}) => this.setState({validBrands: this.state.validBrands.concat({key: value, value: value, text: value})})}
                            />
                            <Form.Field>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <label>Start Time</label>
                                    <DateTimePicker
                                        inputVariant="outlined"
                                        fullWidth
                                        id="start"
                                        value={this.state.startTime}
                                        onChange={date => this.setState({startTime: new Date(date).getTime()})}
                                    />
                                </MuiPickersUtilsProvider>
                            </Form.Field>
                            <Form.Field width={16}>
                                <label>End Time</label>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <DateTimePicker
                                        inputVariant="outlined"
                                        fullWidth
                                        id="end"
                                        value={this.state.endTime}
                                        onChange={date => this.setState({endTime: new Date(date).getTime()})}
                                    />
                                </MuiPickersUtilsProvider>
                            </Form.Field>
                            <Form.Group unstackable widths={2}>
                                <Form.Field>
                                    <Button color="blue" onClick={this.handleSearchBtnClick} fluid>Search</Button>
                                </Form.Field>
                                <Form.Field>
                                    <Button fluid color="black" onClick={this.handleClear}>Clear</Button>
                                </Form.Field>
                            </Form.Group>
                            <Form.Field>
                                <ContingentButton
                                    fluid
                                    color="red"
                                    loading={this.state.isReprocessing}
                                    disabled={!this.state.reprocessButtonEnabled}
                                    onClick={() => this.setState({showReprocessConfirm: true})}
                                    service={this.props.service}
                                    module={this.props.module}
                                    scope={this.state.selectedRowBrands}
                                    allPermissions={this.props.permissions}
                                    user={this.props.user}
                                >Reprocess</ContingentButton>
                            </Form.Field>
                        </Form>
                    </Grid.Column>
                    <Grid.Column width={12} className="masterContainer" style={{paddingLeft: "0px", maxHeight: "95vh", overflowY: "auto", overflowX: "auto"}}>
                        <Grid.Row style={{paddingTop: "2vh"}}>
                            <SelectTable
                                columns={
                                    [
                                        {accessor: "_id", show: false},
                                        {Header: "App Id", accessor: "appId"},
                                        {Header: "Media Id", accessor: "mediaId"},
                                        {Header: "Foreign Id", accessor: "foreignId"},
                                        {
                                            Header: "Published to Queue",
                                            accessor: "publishedToQueue",
                                            filterable: false,
                                            Cell: cell => cell.value == null ? "N/A" : cell.value === true ? "Yes" : "No"
                                        },
                                        {Header: "Brand", accessor: "brand"},
                                        {
                                            Header: "Source",
                                            accessor: "source",
                                            Cell: cell => cell.value == null ? "Register" : cell.value
                                        },
                                        {
                                            Header: "Audit Timestamp",
                                            accessor: "lastUpdate",
                                            filterable: false,
                                            Cell: cell => new Date(cell.value).toLocaleString()
                                        }
                                    ]
                                }
                                defaultPageSize={200}
                                loading={this.state.isLoading}
                                loadingText={this.state.loadingText || ""}
                                selectAll={true}
                                toggleAll={true}
                                selectType="checkbox"
                                toggleSelection={
                                    (_id, hmm, row) => {
                                        console.log("toggle selection", _id, hmm, row);
                                        if (this.state.selectedRows.hasOwnProperty(_id)) {
                                            const {[_id]: undefined, ...updatedSelectedRows} = this.state.selectedRows;
                                            this.setState({selectedRows: updatedSelectedRows}, () => {
                                                console.log("Toggle selection, removed row.  SelectedRows:", this.state.selectedRows);
                                                this.checkReprocessEnabled();
                                            });
                                        } else {
                                            if (this.state.reprocessableBrands.includes(row.brand)) {
                                                this.setState({selectedRows: Object.assign({}, this.state.selectedRows, {[_id]: row})}, () => {
                                                    console.log("Toggle selection, added row.  SelectedRows:", this.state.selectedRows);
                                                    this.checkReprocessEnabled();
                                                });
                                            } else {
                                                this.props.toast("Error", `You do not have permissions to reprocess for the brand ${row.brand}.`);
                                            }
                                        }
                                    }
                                }
                                isSelected={key => this.state.selectedRows.hasOwnProperty(key)}
                                SelectAllInputComponent={() => <div />}
                                getTrProps={(state, rowInfo, column, instance) => {
                                    return {
                                        onClick(event, handleOriginal) {
                                            table.setState({
                                                selectedMediaId: rowInfo.original.mediaId,
                                                selectedForeignId: rowInfo.original.foreignId,
                                                message: rowInfo.original.message,
                                                selectedKey: rowInfo.row._id,
                                                newClicked: false,
                                            });
                                        },
                                        style: {
                                            background: rowInfo && rowInfo.row && table.state.selectedKey === rowInfo.row._id ? "rgba(65, 83, 175, .5)" : "",
                                        }
                                    }
                                }}
                                data={this.state.auditData}
                                fluid
                                style={{height: "95vh"}}
                                defaultSorted={[{id: "lastUpdate", desc: true}]}
                                SubComponent={row =>
                                    <MediumAuditEntry id={row.original._id} source={row.original.source} message={row.original.message} toast={this.props.toast} />
                                }
                            />
                        </Grid.Row>
                    </Grid.Column>
                    <Confirm
                        open={this.state.showReprocessConfirm}
                        header="Reprocess audit items?"
                        content={<MediumAuditReprocessConfirm rows={this.state.selectedRows} />}
                        onCancel={() => this.setState({showReprocessConfirm: false})}
                        onConfirm={() => this.setState({showReprocessConfirm: false, isReprocessing: true}, () => this.sendRegistrationRequest())}
                    />
                </Grid.Row>
            </Grid>
        );
    }
}

export default MediumAuditMasterDetailView;
